.ag-theme-alpine>* {
    font-family: 'Inter', sans-serif;
}

.ag-header-cell-text {
    font-weight: 600 !important;
    font-size: 14px !important;
}

.ag-theme-alpine .ag-root-wrapper {
    border: solid 1px !important;
    border-color: #eaeaea !important;
    border-radius: 6px !important;
}

.ag-theme-alpine .ag-header {
    border-bottom: none !important;
}

/* Toggle */
.slider {
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

input:checked+.slider {
    background-color: #66cb9f;
}

input:focus+.slider {
    box-shadow: 0 0 1px #66cb9f;
}

input:checked+.slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}

.slider.round:before {
    border-radius: 50%;
}

.ib {
    display: inline-block;
}

.progress-bar {
    height: 4px;
    background-color: rgba(5, 114, 206, 0.2);
    width: 100%;
    overflow: hidden;
}

.progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: rgb(5, 114, 206);
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
    0% {
        transform: translateX(0) scaleX(0);
    }

    40% {
        transform: translateX(0) scaleX(0.4);
    }

    100% {
        transform: translateX(100%) scaleX(0.5);
    }
}

.activecallleadsRow {
    background-color: #f3f4f6;
}

.dropdown {
    position: relative;
    color: #333;
    cursor: default;
}

.dropdown .arrow {
    border-color: #6f7071 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: 0.3rem;
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
}

.dropdown .arrow.open {
    border-color: transparent transparent #6f7071;
    border-width: 0 5px 5px;
}

.autoCompleteInput {
    font-size: 1rem;
    background-color: #f3f4f6;
    cursor: default;
    outline: none;
    transition: all 200ms ease;
    width: 100%;
}

.dropdown .options {
    display: none;
    background-color: #fff;
    border-left: 1px solid #9ca3af;
    border-right: 1px solid #9ca3af;
    border-bottom: 1px solid #9ca3af;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: 1px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    margin-bottom: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-overflow-scrolling: touch;
}

.autoSuggestionOptions {
    background-color: #fff;
    border-left: 1px solid #9ca3af;
    border-right: 1px solid #9ca3af;
    border-bottom: 1px solid #9ca3af;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
}

.autoSuggestionOptionsBot {
    background-color: #f3f4f6;
    border-left: 1px solid #9ca3af;
    border-right: 1px solid #9ca3af;
    border-bottom: 1px solid #9ca3af;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 150px;
}

.variableOptions {
    position: absolute;
    z-index: 100;
    background-color: #fff;
}


.dropdown .options.open {
    display: block;
}

.dropdown .option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
    background-color: #f2f9fc;
    color: #333;
}

.notification {
    position: absolute;
    z-index: 1000;
    top: 0;
    right: 0;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
    animation: show 0.5s 1 ease-in-out forwards;
}

@keyframes show {
    0% {
        display: none;
        width: 0;
        opacity: 0;
    }

    100% {
        width: 20rem;
        opacity: 1;
        display: block;
    }
}