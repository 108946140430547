.planModelHide {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 45px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.planModelShow {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 45px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    border: 1px solid #888;
    height: 90%;
    overflow-y: auto;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.pricing-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.planTitle {
    color: #2f1c6a;
    font-weight: 500;
}

.planPrice {
    color: #2f1c6a;
    font-weight: 700;
}

.planFeature {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.plans2 {
    position: relative;
    padding: 40px;
    border: 1px solid #0032FF;
    border-radius: 5px;
    text-align: center;
    height: 450px;
}

.plans1 {
    position: relative;
    padding: 40px;
    border: 1px solid #C1C1C1;
    border-radius: 5px;
    text-align: center;
    height: 450px;
}

.purchasebutton1 {
    border: none;
    padding: 5px;
    border-radius: 5px;
    color: white;
    width: 180px;
    height: 50px;
    background-color: #000000;
}

.purchasebutton2 {
    border: none;
    padding: 5px;
    border-radius: 5px;
    color: white;
    width: 180px;
    height: 50px;
    background-color: #0032FF;
}
